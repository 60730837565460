$white: #fff;
$dark-900: #000;
$dark-700: #1c1210;
$dark-600: #221d1f;
$dark-500: #443c3b;
$gold: #f3cf94;
$brown: #5a2b20;
$gray-100: #f3f3f3;
$gray-200: #E1E1E1;
$gray-400: #a4a4a4;
$gray-500: #464c52;
$success: #77d26c;
$danger: #da0044;
$blue-500: #0091b9;
$blue: #005F79;

//$font-heading: "Fjalla One", sans-serif;
$font-heading: 'Saira Condensed', sans-serif;

$font-base: "Open Sans", sans-serif;

$font-size-xxlarge: 4rem;
$font-size-xlarge: 2.1875rem;
$font-size-large: 2rem;
$font-size-xxxmedium: 1.875rem;
$font-size-xxmedium: 1.5rem;
$font-size-xmedium: 1.25rem;
$font-size-medium: 1.125rem;
$font-size-small: 1.125rem;
$font-size-xsmall: 1rem;
$font-size-xxsmall: 0.75rem;

$font-size-description: 0.875rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
